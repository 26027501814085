const vals = [
  "#00C3A9",
  "#00B720",
  "#008813",
  "#000000",
  "#FFFFFF",
  "#F8E300",
  "#FF6400",
  "#E20000",
  "#AC000D",
  "#9E005F",
  "#6D0E82",
  "#3B3887",
  "#175FDA",
  "#0091E2",
  "#00BCED",
  "#14E4C5",
];

export default vals;
